import React, { useEffect } from "react"
import { redirect, usePathname, useRouter } from "next/navigation"
import { useReadLocalStorage } from "usehooks-ts"
import { NoSsr } from "@mui/base/NoSsr"
import useRoles from "@/hooks/useRoles"
import { UserDetails } from "@/interfaces/UserDetails"
import useUserDetails from "@/hooks/useUserDetails"

type ComponentType = React.ComponentType<any>

const ProtectedAuth = (Component: ComponentType) => {
  const Auth = (props: any) => {
    const router = useRouter()
    const pathname = usePathname()
    const validUser = useReadLocalStorage("accessToken")
    const { data: userDetailsData } = useUserDetails()
    const { isCleaner, isMaintain } = useRoles(userDetailsData?.data as UserDetails)

    useEffect(() => {
      if (!validUser) {
        redirect("/login")
      }
    }, [validUser])

    useEffect(() => {
      if (
        validUser &&
        userDetailsData &&
        (pathname === "/login" || pathname === "/" || pathname === "/maintenance" || pathname === "/cleans" || pathname === "/home")
      ) {
        if (isCleaner) {
          router.push("/cleans")
        } else {
          router.push("/maintenance")
        }
      }
    }, [isCleaner, pathname, router, userDetailsData, validUser])

    return (
      <>
        {validUser ? (
          <NoSsr>
            <Component {...props} />
          </NoSsr>
        ) : null}
      </>
    )
  }
  return Auth
}
export default ProtectedAuth
