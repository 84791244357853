"use client"
import ProtectedAuth from "@/app/ProtectedAuth"
import { Box } from "@mui/material"

const Home = () => {
  return (
    <>
      <Box>Homepage</Box>
    </>
  )
}

export default ProtectedAuth(Home)
